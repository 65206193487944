<template>
  <a-form>
    <a-form-item has-feedback v-bind="validateInfos.Uname">
      <a-input class="a-input" v-model:value="modelRef.Uname">
        <template #prefix>
          <UserOutlined />
        </template>
      </a-input>
    </a-form-item>

    <a-form-item has-feedback v-bind="validateInfos.Pwd">
      <a-input class="a-input" v-model:value="modelRef.Pwd" type="password">
        <template #prefix>
          <LockOutlined />
        </template>
      </a-input>
    </a-form-item>

    <a-button block class="a-input" @click="onSubmit">登录</a-button>
  </a-form>
</template>

<script>
import { defineComponent, ref, reactive, toRaw } from 'vue'
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'
import { Form } from 'ant-design-vue'
import form from '@/mixins/form'

const useForm = Form.useForm
export default defineComponent({
  components: {
    UserOutlined,
    LockOutlined
  },
  mixins: [form],
  setup () {
    const loading = ref(false)

    const modelRef = reactive({
      Uname: 'imbep',
      Pwd: 'asdasd'
    })

    const rulesRef = reactive({
      Uname: [
        {
          required: true,
          message: '请输入用户名'
        }
      ],
      Pwd: [
        {
          required: true,
          message: '请输入密码'
        }
      ]
    })

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    return {
      loading,
      modelRef,
      validateInfos,
      resetFields,
      validate
    }
  },

  mounted () {
    const storeId = this.$route.query.storeId
    this.$store.commit('SET_SCAN_STORE_ID', storeId)
  },

  unmounted () {
    window.onkeydown = () => true
  },

  methods: {
    onSubmit () {
      this.loading = true
      this.validate()
        .then(() => {
          this.$store
            .dispatch('CLogin', toRaw(this.modelRef))
            .then(() => {
              this.loading = false
              this.$message.success('登录成功')

              this.$router.push({ name: 'waimai-index' })
            })
            .catch((e) => {
              this.loading = false
            })
        })
        .catch(() => {
          this.loading = false
          this.$message.error('请正确输入账户信息')
        })
    }
  }
})
</script>

<style lang="less" scoped>

</style>
